.btn-primary {
  background-color: #1a9ed9 !important;
  color: white !important;
}
.btn-primary:hover {
  background-color: #17536f !important;
}
.primary {
  color: #1a9ed9 !important;
}

/* .custom-tooltip {
  background-color: #f1f1f1 !important;
  color: black !important;
  width: 100% !important;
  height: 100% !important;
  padding: 10px !important;
  text-align: start;
} */

.grid {
  display: grid;
}
.grid-container--fill {
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

/* .grid-container--fit {
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
} */
